.about {
  flex-direction: column;
  margin-top: 3em;
  text-align: center;
}

.about__photo {
  height: 240px;
  border-radius: 120px;
  margin-bottom: 40px;
}

.about__name {
  color: var(--clr-primary);
  background-color: transparent;
}

.about__role {
  margin-top: 1.2em;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    margin-top: 2em;
  }
}
