.tab-panel {
  padding: 32px;
}

.tab-label {
  font: 0.9rem 'Poppins', sans-serif !important;
  color: var(--clr-fg) !important;
  text-transform: none !important;
  font-weight: 500 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: var(--clr-primary) !important;
}

.MuiTabs-indicator {
  background-color: var(--clr-primary) !important;
}

.MuiTouchRipple-root {
  display: none !important;
}

.MuiTabs-flexContainer {
  justify-content: center !important;
}

.MuiBox-root.css-10izwvm {
  box-shadow: var(--shadow) !important;
}
