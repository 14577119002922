.experience__title {
  display: block;
  font-weight: 600;
  margin-bottom: 1em;
}

.experience__company {
  color: var(--clr-primary);
}

.experience__details {
  margin-left: 20px;
}

.experience__details-item:before {
  content: '▹';
  position: absolute;
  margin: 0 -20px;
  color: var(--clr-primary);
}
